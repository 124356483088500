<template>
  <PlaceListPlace
    :header="location.region"
    :footer="footerLbl"
    :expanded="expanded"
    :selected="selected"
    :disabled="disabled"
    @click-item="$emit('clickItem')"
    @click-shop="$emit('clickShop')"
  >
    <template #address>
      <p class="block text-[14px] [&>br]:hidden font-normal font-alt pl-[28px] max-w-[96%] leading-none">
        {{ location.address }} {{ location.address_2 }}
        <br />
        {{ location.city }}, {{ location.state }} {{ location.zip }}
      </p>
    </template>

    <li
      v-for="(item, i) of deliverySlots"
      :key="i"
      class="inline-flex justify-between max-w-max"
    >
      <span class="text-teal font-alt mr-[8px] text-xs">{{ item.date.format('ddd') }}</span>
      <span class="font-alt text-xs">{{ item.start }}am-{{ item.end }}pm</span>
    </li>
  </PlaceListPlace>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ToTwelveHour from '@/store/region/utilities/ToTwelveHour'
  import dayjs from 'dayjs'
  import timezone from 'dayjs/plugin/timezone'
  import utc from 'dayjs/plugin/utc'

  dayjs.extend(utc)
  dayjs.extend(timezone)

  export default {
    name: 'DeliveryAddress',
    props: {
      location: {
        type: Object,
        required: true
      },
      expanded: {
        type: Boolean,
        default: false
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    emits: ['clickItem', 'clickShop'],
    computed: {
      ...mapGetters('regions', [
        'xFindRegionFromLocationAndZip',
        'xFindPossibleDeliveryDates',
        'xFindNextDeliveryDate'
      ]),

      region() {
        return this.xFindRegionFromLocationAndZip(this.location, this.location.zip, true)
      },
      deliverySlots() {
        return this.xFindPossibleDeliveryDates(this.region, 6)
      },
      nextDeliverySlot() {
        return this.xFindNextDeliveryDate(this.region)
      },
      footerLbl() {
        return this.nextDeliverySlot
          ? `Order before ${this.nextDeliverySlot.date.format('dddd')} ${ToTwelveHour(
              this.nextDeliverySlot.orderBy,
              ':',
              true
            )} for next day.`
          : ''
      },
      disabled() {
        return this.deliverySlots === null
      }
    }
  }
</script>
