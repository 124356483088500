<template>
  <li
    class="flex-col border-2 rounded bg-white p-[24px]"
    :class="{
      'border-pink-400': expanded,
      'border-gray-200': !expanded,
      'cursor-pointer selectable': !expanded && !disabled,
      'opacity-40': disabled
    }"
    @click="clickItem"
    @click.stop="$emit('clickShop')"
  >
    <div>
      <div class="flex justify-between items-center space-x-6">
        <address class="not-italic mb-[8px] w-full">
          <div class="flex items-center mb-[8px] gap-x-2">
            <button
              type="button"
              class="flex w-5 h-5 px-0.5 py-0 border border-black rounded-full justify-center items-center"
            >
              <span
                v-if="selected"
                class="w-[14px] h-[14px] rounded-full bg-[#FBCFE8]"
              >
              </span>
            </button>
            <strong
              :class="{
                '': !selected,
                'max-w-[120px] text-wrap': selected
              }"
              class="text-base leading-none text-[18px] font-main"
              >{{ header }}</strong
            >

            <div
              v-if="disabled"
              class="flex items-center justify-end"
            >
              <span class="border rounded-md px-2 py-1">Unavailable</span>
            </div>
            <div
              v-if="expanded"
              class="flex justify-end gap-2"
            >
              <div
                v-if="selected"
                class="rounded-[4px] font-normal font-main flex items-center p-[4px] bg-[#FBCFE8]"
              >
                <span class="text-[11px] text-nowrap font-main">Currently shopping</span>
              </div>
            </div>
            <IconChevron
              width="16"
              height="16"
              :class="expanded ? 'transform rotate-180' : ''"
              class="!ml-auto cursor-pointer transition w-[16px] h-[16px]"
              @click="$emit('clickShop')"
            ></IconChevron>
          </div>
          <slot name="address"></slot>
        </address>
      </div>
    </div>
    <template v-if="expanded">
      <span class="h-[1px] bg-[#E5E7EB] ml-[36px] block"></span>
      <div class="pl-[36px] py-[8px]">
        <ol class="grid gap-x-4 gap-y-2 grid-cols-2">
          <slot></slot>
        </ol>
      </div>
      <div
        v-if="footer"
        class="text-pink-400"
      >
        <span class="h-[1px] bg-[#E5E7EB] ml-[36px] block"></span>

        <div class="px-5 flex gap-x-1 pl-[36px] pt-[8px] pr-0 items-center">
          <IconInfo
            width="16"
            height="16"
          ></IconInfo>
          <span class="leading-none text-[10px] font-alt mt-[3px]">{{ footer }}</span>
        </div>
      </div>
    </template>
  </li>
</template>

<script>
  import IconInfo from '~/components/icon/IconInfo.vue'

  export default {
    components: { IconInfo },
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      expanded: {
        type: Boolean,
        default: false
      },
      selected: {
        type: Boolean,
        default: false
      },
      header: {
        type: String,
        default: ''
      },
      footer: {
        type: String,
        default: ''
      }
    },
    emits: ['clickItem', 'clickShop'],
    methods: {
      clickItem() {
        if (!this.disabled) this.$emit('clickItem')
      }
    }
  }
</script>

<style scoped></style>
